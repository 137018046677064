/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import Select from 'react-select';
import { useQueryParamString } from 'react-use-query-param-string';
import clsx from 'clsx';
import { Link } from 'gatsby';
import { Layout, SEO } from '@/components';
import timezones from '../data/timezones.json';

export const Head = ({ location }) => {
  const title = 'Time Converter';
  const description = 'Njano is a cloud-based flexible office management solution designed to manage coworking spaces';
  return <SEO title={title} description={description} location={location} />;
};

const TimeZoneConverter = () => {
  const timeNow = new Date();
  const [startTime] = useQueryParamString('from', timeNow.toISOString(), true);
  const [endTime] = useQueryParamString('to', null, true);

  const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? 'Europe/Paris';
  const [selectedTimezone, setSelectedTimezone] = useState({
    name: timezone,
    offset: new Date().getTimezoneOffset() / -60,
    city: 'Local Time',
    country: ''
  });
  return (
    <Layout dark logo>
      <div className="grid grid-cols-2 py-[5.25rem] px-4 lg:px-[8.75rem] space-y-16">
        <div className="lg:px-16 flex-row items-start justify-center col-span-2">
          <div className="font-content">
            <span className="text-primary md:text-sm space-y-8 text-base font-bold">
              <Link to="/" className="bg-secondary md:text-sm px-6 py-2 text-base font-bold text-white no-underline">
                &#8592; BACK TO HOME
              </Link>
              <h1 className="font-heading md:text-5xl pt-6 pb-2 text-3xl font-bold text-center text-white break-normal">
                Time conversion tool
              </h1>
              <div className="flex flex-col items-center justify-center w-full mt-8">
                <div className="lg:space-y-16 w-full max-w-3xl py-10 space-y-8">
                  <div className="flex items-center ml-4 text-white">
                    <div className="text-md lg:text-xl mr-1 font-bold">UTC</div>
                    <div className="text-md lg:inline lg:text-lg hidden font-bold">(Coordinated Universal Time)</div>
                  </div>
                  <div className="text-darkGray rounded-3xl border-secondary focus:border-2 bg-white !mt-3">
                    <div className="p-[23px]">
                      <div className={clsx('flex items-center justify-center', { 'justify-between': endTime })}>
                        <div className="font-kanit lg:text-2xl flex justify-start space-x-4 text-xl font-bold">
                          <div className="font-content font-black">{endTime !== null ? 'From' : null}</div>
                          <div>
                            {new Intl.DateTimeFormat('sv-SE', {
                              hour12: false,
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              timeZone: 'UTC'
                            }).format(new Date(startTime) ?? new Date())}
                          </div>
                        </div>
                        {endTime !== null ? (
                          <div className="font-kanit lg:text-2xl flex justify-start space-x-4 text-xl font-bold">
                            <div className="font-content font-black">To</div>
                            <div>
                              {new Intl.DateTimeFormat('sv-SE', {
                                hour12: false,
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                timeZone: 'UTC'
                              }).format(new Date(endTime) ?? new Date())}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <Select
                    id="timezone-select"
                    getOptionValue={(option) => option.offset}
                    getOptionLabel={(option) => option.name}
                    formatOptionLabel={(option) => `${option.country} ${option.country ? '-' : ''} ${option.city}`}
                    instanceId="timezone-select"
                    onChange={setSelectedTimezone}
                    value={selectedTimezone}
                    inputProps={{
                      placeholder: 'Select Timezone'
                    }}
                    name="timezone"
                    classNames={{
                      control: () =>
                        'w-full p-4 !bg-transparent !rounded-3xl border border-white !focus:border-secondary !text-white focus:border-secondary hover:border-secondary',
                      input: () => '!text-white font-kanit font-extralight text-lg text-left',
                      placeholder: () =>
                        '!text-white font-kanit font-extralight text-lg text-left !placeholder:text-white',
                      singleValue: () => '!text-white font-kanit font-extralight text-lg text-left',
                      menu: () => '!bg-darkGray z-20',
                      option: ({ isFocused, isSelected }) =>
                        clsx(
                          isFocused && '!bg-secondary !text-darkGray',
                          isSelected && '!bg-secondary !text-darkGray',
                          '!bg-darkGray !text-white !font-kanit !font-extralight !text-lg text-left'
                        )
                    }}
                    required
                    options={timezones}
                    placeholder="Your country"
                    className="placeholder:text-white z-20 w-full text-white bg-transparent outline-none"
                  />
                  <div className="flex items-center ml-4 text-white">
                    <div className="text-md lg:text-xl mr-1 font-bold">{`UTC${
                      selectedTimezone.offset > 0 ? '+' : ''
                    }${selectedTimezone.offset}`}</div>
                    <div className="text-md lg:inline lg:text-lg hidden font-bold">
                      {' '}
                      ({`${selectedTimezone.country}${selectedTimezone.country ? '/' : ''}${selectedTimezone.city}`})
                    </div>
                  </div>
                  <div className="text-darkGray rounded-3xl border-secondary focus:border-2 bg-white !mt-3">
                    <div className="p-[23px]">
                      <div className={clsx('flex items-center justify-center', { 'justify-between': endTime })}>
                        <div className="font-kanit lg:text-2xl flex justify-start space-x-4 text-xl font-bold">
                          <div className="font-content font-black">{endTime !== null ? 'From' : null}</div>
                          <div>
                            {new Intl.DateTimeFormat('sv-SE', {
                              hour12: false,
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              timeZone: selectedTimezone.name
                            }).format(new Date(startTime) ?? new Date())}
                          </div>
                        </div>
                        {endTime !== null ? (
                          <div className="font-kanit lg:text-2xl flex justify-start space-x-4 text-xl font-bold">
                            <div className="font-content font-black">To</div>
                            <div>
                              {new Intl.DateTimeFormat('sv-SE', {
                                hour12: false,
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                timeZone: selectedTimezone.name
                              }).format(new Date(endTime) ?? new Date())}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default TimeZoneConverter;
